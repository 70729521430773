import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { TaxSavingsCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellToutMortgage } from './constants'
import { usePages } from '../constants'

const TaxSavingsPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, mortgagePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('TaxSavingsPage.subTitle', {
        defaultValue: 'How much will my mortgage save me in taxes?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('TaxSavingsPage.textContent', {
                  defaultValue:
                    "Homeownership has many benefits, even when it comes to your taxes. You can reduce your taxable income by the amount of money you've paid in mortgage interest during the year. Specifically, you can deduct interest on the first $750,000 of your mortgage. See how much your mortgage might save you over time with this calculator.",
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('TaxSavingsPage.textContent2a', {
                  defaultValue:
                    'While this calculator can help you understand your potential tax savings, all numbers provided here are estimates. Please ',
                }),
                data: {},
              },
              {
                nodeType: 'hyperlink',
                content: [
                  {
                    nodeType: 'text',
                    marks: [],
                    value: t('TaxSavingsPage.textContent3b', {
                      defaultValue: 'talk to a Mortgage Loan Officer',
                    }),
                    data: {},
                  },
                ],
                data: {
                  uri: `/home-lending/mortgage-team`,
                },
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('TaxSavingsPage.textContent3c', {
                  defaultValue: ' about what your actual savings may be.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: mortgagePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('TaxSavingsPage.pageTitle', {
              defaultValue: 'Mortgage Tax Savings Calculator',
            })}
            pathname="/calculators/mortgage/tax-savings"
          />
          <CalculatorSectionModule
            pageTitle={t('TaxSavingsPage.title', {
              defaultValue: 'Mortgage Calculators',
            })}
            sections={pageData}
            calculator={<TaxSavingsCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellToutMortgage />
        </Layout>
      )}
    </StaticTemplate>
  )
}

TaxSavingsPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { TaxSavingsPage }
export default TaxSavingsPage
